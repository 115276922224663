<template>
  <div class="means">
    <PageTopNav
      :pageNavShow="false"
      :addShow="true"
      :tabList="tabList"
      @addMeans="addMeans"
      @changeTab="changeTab"
    >
      <p slot="addName">添加生产记录</p>
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="lists" v-if="meansList.length">
          <MyTable
            class="animated fadeInUp"
            :tableData="meansList"
            :configTable="configTable"
            @handleDelete="handleDelete"
            @handleEdit="handleEdit"
          ></MyTable>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          v-if="meansList.length"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增/修改生产记录 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="666px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog()"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          <span>{{ bigPopTitle }}</span>
        </div>
        <div class="addForm">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="选择烘干记录"
              :label-width="formLabelWidth"
              prop="dry_id"
            >
              <el-select
                v-model="form.dry_id"
                filterable
                reserve-keyword
                remote
                :remote-method="selectFilter"
                placeholder="搜索计划名称"
                v-loadMore="loadMoreFilesData"
                :loading="loadingSelect"
                @change="changeSelect"
              >
                <el-option
                  :label="item.dry_name"
                  :value="item.dry_id"
                  v-for="item in planList"
                  :key="item.dry_id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="生产完成数量"
              :label-width="formLabelWidth"
              prop="num"
            >
              <el-input
                type="number"
                v-model.number="form.num"
                placeholder="请输入生产完成数量"
                @input="inputNumber"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="单位"
              :label-width="formLabelWidth"
              prop="unit"
            >
              <el-input
                v-model="form.unit"
                placeholder="请输入产品单位"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="规格"
              :label-width="formLabelWidth"
              prop="model"
            >
              <el-input
                v-model="form.model"
                placeholder="请输入产品规格"
              ></el-input>
            </el-form-item>

            <el-form-item label=" " class="dialog-footer">
              <el-button
                class="ok-button"
                type="primary"
                @click="okAdd('form')"
              >
                确 定
              </el-button>
              <el-button class="no-button" @click="noAdd('form')">
                取 消
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getProcessListApi,
  postEditProcessApi,
  postAddProcessApi,
  getDryListApi,
  delProcessApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      loading: false,
      loadingSelect: false,
      configTable: [
        {
          prop: "process_name",
          label: "生产名称",
          width: "260",
        },
        {
          prop: "product_name",
          label: "产品",
          width: "260",
        },
        {
          prop: "unit",
          label: "产品单位",
          width: "260",
        },
        {
          prop: "model",
          label: "产品规格",
          width: "260",
        },

        {
          prop: "num",
          label: "生产数量",
          width: "160",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "",
        },

        {
          prop: "",
          label: "操作",
          width: "260",
          delType: true,
          editType: true,
        },
      ],

      planList: [],
      planTotal: 0,
      planPn: 1,
      // 分页
      total: 0, // 总数
      last_page: 1, //末尾页
      per_page: 10, // 一页个数
      current_page: 1, // 当前页
      // 库存记录的分页
      supplyTotal: 0, // 总数
      supplyPer_page: 10, // 一页个数
      supplyCurrent_page: 1, // 当前页
      // 筛选条件
      title: undefined,
      cid: undefined,
      // 生产记录分类列表
      tabList: [],
      // 生产记录列表
      meansList: [],
      isFocusShow: true,
      // 入库/出库
      dialogVisible: false,
      // 生产记录入库/出库
      quantity: "",
      purposeVal: "",
      PopTitle: "",
      inputLab: "",
      resource_id: "",
      key_word: "",

      //   添加
      addShow: false,
      bigPopTitle: "",
      // 添加生产记录表单
      form: {
        name: "",
        num: "",
        unit: "",
        model: "",
        product_id: "",
        dry_id: "",
      },
      // 生产记录ID
      id: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        model: [{ required: true, message: "请输入规格", trigger: "blur" }],
        num: [{ required: true, message: "请输入生产数量", trigger: "blur" }],
        dry_id: [
          { required: true, message: "请选择烘干记录", trigger: "change" },
        ],
      },

      formLabelWidth: "120px",
    };
  },
  watch: {
    addShow(newVal) {
      if (!newVal) {
        this.form = {
          name: "",
          num: "",
          unit: "",
          model: "",
          product_id: "",
          dry_id: "",
        };
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getProcessList();
  },
  methods: {
    changeSelect(val) {
      const obj = this.planList.find((item) => item.dry_id === val);
      this.form.product_id = obj.product_id;
    },
    handleDelete({ process_id }) {
      this.$confirm("此操作将删除该生产记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await delProcessApi({ process_id });
          if (code === 204) {
            this.getProcessList();
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    async handleEdit({ dry_id,process_id }) {
      let obj = this.meansList.find((item) => item.process_id === process_id) || {};
      this.form.process_id = process_id;
      this.form.dry_id = dry_id;
      this.form.name = obj.dry_name;
      this.form.num = obj.num;
      this.form.unit = obj.unit;
      this.form.model = obj.model;
      this.form.product_id = obj.product_id;
      this.planList = [
        {
          dry_name: obj.dry_name,
          dry_id: obj.dry_id,
        },
      ];
      this.bigPopTitle = "编辑生产记录";
      this.addShow = true;
    },
    inputNumber() {
      if (this.form.num <= 0) this.form.num = "";
    },
    loadMoreFilesData() {
      if (this.planList.length && this.planList.length == this.planTotal)
        return;
      this.planPn++;
      this.selectFilter(this.key_word);
    },

    async selectFilter(key_word) {
      this.key_word = key_word;
      if (!key_word) {
        this.planList = [];
        return;
      }
      if (this.planPn == 1) {
        this.planList = [];
      }
      this.loadingSelect = true;
      const { code, results } = await getDryListApi({
        key_word,
        pn: this.planPn,
      });
      if (code !== 0) return;
      this.planList = [...this.planList, ...results.data];
      this.planPn = results.page_num;
      this.planTotal = results.count;
      this.loadingSelect = false;
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },

    // 获取生产记录详情
    // async getAddResourceApi() {
    //   const { code, results } = await getAddResourceApi({
    //     resource_id: this.id,
    //   });
    //   if (code === 0) {
    //     results.yank = results.yank + "";
    //     this.form = results;
    //     this.form.supplier = results.cid;

    //     this.form.custom_state
    //       ? (this.form.custom_state = true)
    //       : (this.form.custom_state = false);
    //   }
    // },
    // 添加生产记录
    async addProcess() {
      const { code } = await postAddProcessApi({
        // manor_id: this.farmID,
        ...this.form,
      });
      if (code === 0) {
        this.getProcessList();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
      }
    },
    // 修改生产记录信息
    async editProcess() {
      const { code } = await postEditProcessApi({
        ...this.form,
      });
      if (code === 0) {
        this.getProcessList();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.$refs["form"].resetFields();
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.current_page = val;
      this.getProcessList();
    },
    // 获取生产记录列表
    async getProcessList() {
      showFullScreenLoading();
      const { code, results } = await getProcessListApi({
        // manor_id: this.farmID,
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.page_size;
        this.current_page = results.page_num;
        this.meansList = results.data;
      }
      tryHideFullScreenLoading();
    },

    changeTab(cid) {
      this.cid = cid.id;
      this.current_page = 1;
      this.title = undefined;
      this.getProcessList();
    },

    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "添加生产记录") {
            this.addProcess();
          } else {
            this.editProcess();
          }
          this.addShow = false;
        } else {
          return false;
        }
      });
    },
    // 编辑生产记录
    editMean(id) {
      this.id = id;
      // this.getAddResourceApi();
      this.bigPopTitle = "编辑生产记录";
      this.addShow = true;
    },
    // 添加生产记录
    addMeans() {
      this.bigPopTitle = "添加生产记录";
      this.addShow = true;
    },

    setAddClose() {
      this.addShow = false;
      this.form = {};
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .items {
          margin: 0 40px 34px 0;
          width: 307px;
          height: 328px;
          background: #081c30;
          border: 1px solid #385576;
          padding: 13px 15px 0;
          display: flex;
          flex-direction: column;

          .col1 {
            width: 100%;
            height: 184px;
            margin-bottom: 14px;
            position: relative;

            .bgImgs {
              width: 100%;
              height: 100%;
            }

            .tags {
              min-width: 108px;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              padding: 0 8px;
              position: absolute;
              left: 0;
              top: 12px;
              color: #fff;
              font-weight: Bold;
              font-size: 16px;
              background-color: #6200bd;
            }

            .icon-btn {
              display: flex;
              align-items: center;
              display: none;
              position: absolute;
              right: 13px;
              top: 12px;

              img {
                width: 33px;
                height: 32px;
                cursor: pointer;
                margin-right: 9px;

                :last-of-type {
                  margin-right: 0;
                }
              }
            }
          }

          .col2 {
            width: 100%;
            border-radius: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            > div {
              width: 5px;
              height: 5px;
              background: #a46dd7;
              border-radius: 50%;
              margin-right: 11px;
            }

            p {
              width: calc(100% - 5px);
              height: 20px;
              color: #fff;
              font-size: 14px;
              margin: 0;
            }
          }

          .col3 {
            width: 100%;
            min-height: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a9b8c8;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .col4 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons1,
            .buttons2,
            .buttons3 {
              width: 82px;
              height: 28px;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;
              line-height: 28px;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            .buttons1 {
              background: #67c23a;
            }

            .buttons2 {
              background: #3e90e5;
            }

            .buttons3 {
              background: #67c23a;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }

          :hover {
            .icon-btn {
              display: block;
            }
          }
        }
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;
      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../../assets/image/land/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .addForm {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          color: #fff;
          margin-right: 16px;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }

  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
